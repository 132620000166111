<!--下单发货-->
<template>
    <div style="min-width: 1550px">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <div class="mainOrder">
                <el-card class="box-card">
                    <el-row>
                        <el-col :span="3">
                            <div class="orderText background-color-blue">寄</div>
                        </el-col>
                        <el-col :span="21">
                            <el-button class="pull-right" @click="intelligentFillingOn(1)">智能填写</el-button>
                            <el-button class="pull-right" style="margin-right: 10px" @click="getDeliveryAddress">地址簿</el-button>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-20">

                        <el-form-item label="姓名:" prop="deliveryName">
                            <el-input size="mini" v-model="form.deliveryName"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:" prop="deliveryPhone">
                            <el-input size="mini" v-model="form.deliveryPhone"></el-input>
                        </el-form-item>
                        <el-form-item label="省市区:" prop="" :required="true">
                            <el-select size="mini" class="elm-select-r" v-model="form.proCode"
                                       @change="getDeliveryPro(form.proCode,'city','order','','click')" placeholder="省">
                                <el-option
                                        v-for="item in deliveryOptions"
                                        :key="item.adcode"
                                        :label="item.name"
                                        :value="item.adcode">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="form.cityCode"
                                       @change="getDeliveryPro(form.cityCode,'area','order','','click')" placeholder="市">
                                <el-option
                                        v-for="item in deliveryCityOptions"
                                        :key="item.adcode"
                                        :label="item.name"
                                        :value="item.adcode">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="form.areaCode"
                                       @change="getDeliveryPro(form.areaCode,'site','order','','click')" placeholder="区">
                                <el-option
                                        v-for="item in deliveryAreaOptions"
                                        :key="item.adcode"
                                        :label="item.name"
                                        :value="item.adcode">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="form.deliveryVillageName" placeholder="街道">
                                <el-option
                                        v-for="item in deliverySiteOptions"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="详细地址:" prop="address">
                            <el-input size="mini" v-model="form.address"></el-input>
                        </el-form-item>
                        <el-form-item label="公司名称:" prop="shopName">
                            <el-input size="mini" v-model="form.shopName"></el-input>
                        </el-form-item>

                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <el-row>
                        <el-col :span="3">
                            <div class="orderText background-color-red">收</div>
                        </el-col>
                        <el-col :span="21">

                            <el-button class="pull-right" @click="intelligentFillingOn(2)">智能填写</el-button>
                            <el-button class="pull-right" style="margin-right: 10px" @click="getReceiveAddress">历史收货人</el-button>
                            <el-button class="pull-right" @click="importHistoricalConsigneeOn()">导入历史收货人</el-button>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-20">

                        <el-form-item label="姓名:" prop="receiveName">
                            <el-input size="mini" v-model="form.receiveName"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:" prop="receivePhone">
                            <el-input size="mini" v-model="form.receivePhone"></el-input>
                        </el-form-item>
                        <el-form-item label="省市区:" prop="" :required="true">
                            <el-select size="mini" class="elm-select-r" v-model="form.receiveProCode" @change="getCity('order','','click')"
                                       placeholder="省">
                                <el-option
                                        v-for="item in receiveOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="form.receiveCityCode" @change="getArea('order','','click')"
                                       placeholder="市">
                                <el-option
                                        v-for="item in cityOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="form.receiveAreaCode" @change="getSite('order','','click')"
                                       placeholder="区">
                                <el-option
                                        v-for="item in areaOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="form.villageId" @change="setSiteType('order','')"
                                       placeholder="站点">
                                <el-option
                                        v-for="item in siteOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    <span style="float: left">{{item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type===2? '[' + item.pname + ']' :'' }}</span>
                                </el-option>
                            </el-select>
                            <!--<el-cascader placeholder="请选择省市区" filterable clearable :options="receiveOptions"-->
                            <!--:props="{ value: 'code',label: 'name',children: 'children'}"-->
                            <!--:format-el="formatLabel"-->
                            <!--&gt;-->
                            <!--<template slot-scope="{ data }">-->
                            <!--<span>{{ data.type===2?data.name + '[' + data.pname + ']': data.name}}</span>-->
                            <!--</template>-->
                            <!--</el-cascader>-->
                        </el-form-item>
                        <el-form-item label="详细地址:" prop="receiveAddress">
                            <el-input size="mini" v-model="form.receiveAddress"></el-input>
                        </el-form-item>
                        <el-form-item label="公司名称:" prop="receiveShopName">
                            <el-input size="mini" v-model="form.receiveShopName"></el-input>
                        </el-form-item>
                    </el-row>
                </el-card>
            </div>
            <div class="box-card—bottom-main margin-top-20">
                <el-card class="box-card—bottom">
                    <el-row>
                        <span class="goodsDetailText">物品信息</span>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="货物名称:" prop="goodName">
                                <el-input size="mini" v-model="form.goodName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" >
                            <el-form-item label="重量:" prop="goodsWeight">
                                <el-input size="mini" v-model="form.goodsWeight" style="margin-top:5px;">
                                  <template slot="append">KG</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="体积:" prop="goodsVolume">
                                <el-input size="mini" v-model="form.goodsVolume" style="margin-top:5px;">
                                  <template slot="append">m³</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="货物件数:" prop="unit">
                                <el-input size="mini" v-model="form.unit" style="margin-top:5px;">
                                  <template slot="append">件</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="代收货款(元):" prop="paragraph">
                                <el-input size="mini" v-model="form.paragraph"  style="margin-top:5px;">
                                  <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                          <span v-if="bankFlag == 1" style="font-size: 12px;color: red;margin-top: -10px;float: left;padding-left:120px">
                                未绑定银行卡，请到"修改信息"中绑定银行卡
                              </span>
                          <p v-else style="font-size: 12px;margin-top: -10px;display: flex;float: left;padding-left:120px">
                            <span style="margin-right: 8px;">{{bankName}}</span>
                            <span>{{bankCard}}</span>
                          </p>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="包装:" prop="packageId">
                                <el-select size="mini" class="elm-select-w" v-model="form.packageId" placeholder="请选择" style="width: 100%">
                                    <el-option
                                            v-for="item in optionsPackaging"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="运费付款方式:" prop="paymentType">
                                <el-radio-group v-model="form.paymentType" size="mini">
                                    <el-radio-button label="1">提付</el-radio-button>
                                    <el-radio-button label="2">现付</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:20px;">
                        <el-form-item label="货物照片:" prop="name">
                            <el-upload
                                    class="avatar-uploader"
                                    action=""
                                    list-type="picture-card"
                                    :http-request="imgHttpRequest"
                                    :with-credentials='true'
                                    :file-list="fileImgList"
                                    :limit="3"
                                    :on-success="handleAvatarSuccess"
                                    :on-remove="(file,fileList) => {fileImgList = fileList;}"
                                    :on-change="(file,fileList) => {fileImgList = fileList;}"
                                    :before-upload="beforeAvatarUpload"
                                    :on-exceed ="handleExceed"
                                    >
                                <!--<img v-if="imageUrl" :src="imageUrl" class="avatar">-->
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>

                    </el-row>
                    <el-row style="float: right;right: 20px;margin-bottom: 20px">
                        <el-button type="primary" @click="createOrder('form')">下一步</el-button>
                    </el-row>
                </el-card>
            </div>
        </el-form>
      <div style="justify-content: center;display: flex;">
        <div style=" display: flex;position: fixed;bottom: 10px;align-items: center"  v-if="isRealname">
          <i class="el-icon-warning-outline"></i>
          <p style="margin-right: 5px;font-size: 12px">应国家法律规定，发货需要实名认证</p>
          <el-button type="primary" size="mini" @click="authenticationDialogVisible=true">实名认证</el-button>
        </div>
      </div>



        <el-drawer
                title="地址簿"
                :visible.sync="addressDialogFormVisible"
                direction="rtl"
                :before-close="handleCloseDrawerDialogFormVisible"
                :wrapperClosable = "false"
        >
            <div class="addressBook" v-show="isAdd == true">
                <!--<el-radio-group v-for="(item) in addressBook" :key="item.id" v-model="item.id">-->
                <div :class="['addressBookContent',addressBookRadio === item.id?'select_addressBookContent':'no_select_addressBookContent']"
                     v-for="(item,index) in addressBook" :key="item.id" @click="handleDeliveryAddress(item.id)">
                    <!--<el-radio>-->
                    <el-row>
                        <el-col :span="12">
                            <el-input size="mini" :disabled="item.disabled" maxlength="11" v-model="item.deliveryName"></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-input size="mini" :disabled="item.disabled" maxlength="11" v-model="item.deliveryPhone"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-col :span="12">
                            <el-input size="mini" :disabled="item.disabled" v-model="item.shopName"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-col :span="24">
                            <el-select size="mini" class="elm-select-r" v-model="item.proCode" :disabled="item.disabled"
                                       @change="getDeliveryPro(item.proCode,'city','edit',item,'click')" placeholder="省">
                                <el-option
                                        v-for="data in deliveryOptionsEdit"
                                        :key="data.adcode"
                                        :label="data.name"
                                        :value="data.adcode">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="item.cityCode" :disabled="item.disabled"
                                       @change="getDeliveryPro(item.cityCode,'area','edit',item,'click')" placeholder="市">
                                <el-option
                                        v-for="data in deliveryCityOptionsEdit"
                                        :key="data.adcode"
                                        :label="data.name"
                                        :value="data.adcode">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="item.areaCode" :disabled="item.disabled"
                                       @change="getDeliveryPro(item.areaCode,'site','edit',item,'click')" placeholder="区">
                                <el-option
                                        v-for="data in deliveryAreaOptionsEdit"
                                        :key="data.adcode"
                                        :label="data.name"
                                        :value="data.adcode">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="item.villageName" :disabled="item.disabled" placeholder="街道">
                                <el-option
                                        v-for="data in deliverySiteOptionsEdit"
                                        :key="data.name"
                                        :label="data.name"
                                        :value="data.name">
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-col :span="24">
                            <el-input size="mini" :disabled="item.disabled" v-model="item.address"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
<!--                        <el-radio v-model="addressBookRadio" :label="item.id">{{''}}</el-radio>-->

                        <el-button size="mini" type="primary" v-if="!item.disabled" style="float: right;margin-left: 10px"
                                   @click="editDeliveryAddress(item,index)">保存
                        </el-button>
                        <el-button size="mini" type="primary" v-else style="float: right;margin-left: 10px"
                                   @click="editAddress(item,index)">编辑
                        </el-button>
                        <el-button size="mini" style="float: right;" v-if="!item.disabled"
                                   @click="cancelEdit(item,index)">取消
                        </el-button>
                    </el-row>
                    <!--</el-radio>-->

                </div>
                <!--</el-radio-group>-->

            </div>
          <div class="addressBook" v-show="isAdd == false" style="padding:20px">
            <el-row >
              <el-col :span="24">
                <el-input  maxlength="11" v-model="bookForm.deliveryName" placeholder="请输入发货人姓名"></el-input>
              </el-col>
            </el-row>
            <el-row class="margin-top-10">
              <el-col :span="24">
                <el-input maxlength="11" v-model="bookForm.deliveryPhone" placeholder="请输入发货人手机号码"></el-input>
              </el-col>
            </el-row>

            <el-row class="margin-top-10">
              <el-col :span="24">
                <el-select  class="elm-select-r" v-model="bookForm.proCode" placeholder="省" @change="getDeliveryPro(bookForm.proCode,'city','edit',bookForm,'click')">
                  <el-option
                      v-for="data in deliveryOptionsEdit"
                      :key="data.adcode"
                      :label="data.name"
                      :value="data.adcode">
                  </el-option>
                </el-select>
                <el-select  class="elm-select-r" v-model="bookForm.cityCode" placeholder="市" @change="getDeliveryPro(bookForm.cityCode,'area','edit',bookForm,'click')">

                  <el-option
                      v-for="data in deliveryCityOptionsEdit"
                      :key="data.adcode"
                      :label="data.name"
                      :value="data.adcode">
                  </el-option>
                </el-select>
                <el-select  class="elm-select-r" v-model="bookForm.areaCode"
                           @change="getDeliveryPro(bookForm.areaCode,'site','edit',bookForm,'click')" placeholder="区">
                  <el-option
                      v-for="data in deliveryAreaOptionsEdit"
                      :key="data.adcode"
                      :label="data.name"
                      :value="data.adcode">
                  </el-option>
                </el-select>
                <el-select  class="elm-select-r" v-model="bookForm.villageName" placeholder="街道" @change="changeThis">
                  <el-option
                      v-for="data in deliverySiteOptionsEdit"
                      :key="data.name"
                      :label="data.name"
                      :value="data.name">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="margin-top-10">
              <el-col :span="24">
                <el-input  v-model="bookForm.address" placeholder="请补充详细地址，如：门牌号、街道"></el-input>
              </el-col>
            </el-row>
            <el-row class="margin-top-10">
              <el-col :span="24">
                <el-input  v-model="bookForm.shopName" placeholder="请输入发货公司/店名"></el-input>
              </el-col>
            </el-row>
          </div>
            <div class="text-right paddingRight" v-show="isAdd == true">
                <el-button @click="newAdd()">新增</el-button>
                <el-button type="primary" @click="confirmAddressBook">确定</el-button>
            </div>
            <div class="text-right paddingRight" v-show="isAdd == false">
              <el-button @click="goBack()">返回</el-button>
              <el-button type="primary" @click="saveAdd">保存</el-button>
            </div>
        </el-drawer>


        <el-drawer
                title="地址簿"
                :visible.sync="addressReceiveDialogFormVisible"
                direction="rtl"
                :before-close="handleCloseDrawerReceiveDialogFormVisible"
                :wrapperClosable = "false"
        >
            <div class="addressBook">
                <div style="padding: 20px">
                    <el-input placeholder="请输入关键字进行搜索" v-model="queryReceiveCondition">
                        <el-button slot="append" icon="el-icon-search" @click="getReceiveAddress"></el-button>
                    </el-input>
                </div>

                <!--<el-radio-group v-for="(item) in addressBook" :key="item.id" v-model="item.id">-->
                <div :class="['addressBookContent',activeReceiveIndex === index?'select_addressBookContent':'no_select_addressBookContent']"
                     v-for="(item,index) in addressBookReceive" :key="item.id" @click="selectReceiveExtraction(index)">
                    <!--<el-radio>-->
                    <el-row>
                        <el-col :span="12">
                            <el-input size="mini" :disabled="item.disabled" maxlength="11" v-model="item.receiveName"></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-input size="mini" :disabled="item.disabled" maxlength="11" v-model="item.receivePhone"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-col :span="12">
                            <el-input size="mini" :disabled="item.disabled" v-model="item.shopName"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-col :span="24">
                            <el-select size="mini" class="elm-select-r" v-model="item.proCode" :disabled="item.disabled"
                                       @change="getCity('edit',item,'click')" placeholder="省">
                                <el-option
                                        v-for="data in receiveOptionsEdit"
                                        :key="data.code"
                                        :label="data.name"
                                        :value="data.code">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="item.cityCode" :disabled="item.disabled"
                                       @change="getArea('edit',item,'click')" placeholder="市">
                                <el-option
                                        v-for="data in item.cityOptionsEdit"
                                        :key="data.code"
                                        :label="data.name"
                                        :value="data.code">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="item.areaCode" :disabled="item.disabled"
                                       @change="getSite('edit',item,'click')" placeholder="区">
                                <el-option
                                        v-for="data in item.areaOptionsEdit"
                                        :key="data.code"
                                        :label="data.name"
                                        :value="data.code">
                                </el-option>
                            </el-select>
                            <el-select size="mini" class="elm-select-r" v-model="item.villageId" :disabled="item.disabled"
                                       @change="setSiteType('edit',item)" placeholder="街道">
                                <el-option
                                        v-for="data in item.siteOptionsEdit"
                                        :key="data.id"
                                        :label="data.name"
                                        :value="data.id">
                                    <span style="float: left">{{data.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ data.type===2? '[' + data.pname + ']' :'' }}</span>
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-col :span="24">
                            <el-input size="mini" :disabled="item.disabled" v-model="item.address"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="margin-top-10">
                        <el-button size="mini" type="primary" v-if="!item.disabled" style="float: right;margin-left: 10px"
                                   @click="createReceiveAddress(item,index)">保存
                        </el-button>
                        <el-button size="mini" type="primary" v-else style="float: right;margin-left: 10px"
                                   @click="editReceiveAddress(item,index)">编辑
                        </el-button>
                        <el-button size="mini" style="float: right;" v-if="!item.disabled"
                                   @click="cancelEdit(item,index)">取消
                        </el-button>
                    </el-row>
                    <!--</el-radio>-->

                </div>
                <!--</el-radio-group>-->

            </div>
            <div class="text-right paddingRight">
                <el-button type="primary" @click="confirmAddressReceiveBook">确定</el-button>
            </div>
        </el-drawer>
        <el-dialog
                title="智能填写"
                :visible.sync="intelligentFillingDialogVisible"
                width="37%"
                center>
            <div class="padding20">
                <el-input
                        type="textarea"
                        placeholder="粘贴或输入整段地址，点击“识别”会自动拆分信息，例如：李小二，136****6611，云南省昆明市官渡区某某街道某某大厦666号"
                        v-model="intelligentFillingText"
                        :rows="6"
                        maxlength="300"
                        show-word-limit
                >
                </el-input>
            </div>

            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: space-between">
                <div style="color: #fe5100;font-size: 12px;line-height: 28px">*地址自动识别无法保证100%准确，如有误差请手动修改</div>
                <div>
                    <el-button size="mini" @click=" intelligentFillingText =''">重 置</el-button>
                    <el-button size="mini" type="primary" @click="intelligentFillingCheckAddress">识 别</el-button>
                </div>

            </span>
        </el-dialog>
        <el-dialog
                :visible.sync="importHistoricalConsigneeDialogVisible"
                width="20%"
                center=""
                style="margin-top: 150px"
        >
            <div class="padding20">
                <SendMobileCode @sendData = sendDataGet></SendMobileCode>
                <!--<el-input v-model="importHistoricalConsigneeForm.cPhone" placeholder="请输入手机号" style="width: 100%;margin:0 auto"></el-input>-->
                <!--<div style="display: flex;margin:10px 0;" >-->
                    <!--<el-input v-model="importHistoricalConsigneeForm.code" placeholder="请输入验证码" style="margin-right: 10px;"></el-input>-->
                    <!--<el-button type="primary" @click="open()" :disabled="countdown > 0">  {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</el-button>-->
                <!--</div>-->

                <el-button type="primary" style="width: 100%;" @click="receiveExportOn">导入</el-button>
            </div>

        </el-dialog>
        <el-dialog
                :visible.sync="orderSuccessDialogVisible"
                width="25%"
                center
                style="margin-top: 150px"
        >
            <div class="padding20 text-center">
                <h3 class="text-left">开单号码:{{orderNo}}</h3>
                <img style="width: 80px;height: 80px" src="../assets/img/completeorder.png">
                <h3 class="text-center">下单成功</h3>
                <div class="margin-top-20">
                    <el-button @click="toDetail">查看订单</el-button>
                    <el-button type="primary" @click="orderSuccessDialogVisible = false">在下一单</el-button>
                </div>
            </div>

        </el-dialog>
        <el-dialog
            title="实名认证"
            :visible.sync="authenticationDialogVisible"
            width="30%"
            >
          <div style="display: flex;width: 80%;text-align: center;margin:5px auto">
            <p style="min-width: 80px;line-height: 40px">姓名:</p>
            <el-input  v-model="inputName" placeholder="请输入内容"></el-input>
          </div>
          <div style="display: flex;width: 80%;text-align: center;margin:5px auto">
            <p style="min-width: 80px;line-height: 40px">身份证号:</p>
            <el-input  v-model="inputIdCard" placeholder="请输入身份证号"></el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="authentication">提交</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
    import axios from 'axios';
    import SendMobileCode from '../components/SendMobileCode';
    import {decimalTow,isNotzero} from '../utils/utils'
    import { findCustomerBankInfo} from "../api/selfHelpLoan";
    import {checkIdCard,matchRegion, findDeliveryAddress, saveDeliveryAddress, findReceiveAddress, createReceiveAddress, createOrder, checkAddress, receiveExport,wxLogin} from "../api/deliveryOrder";
    import {findMyReceiveOrder} from "@/api/myOrderList";
    export default {
        name: "deliveryOrder",
        components:{
            SendMobileCode
        },
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("手机号不能为空"));
                } else {
                    const reg = /^1[3-9]\d{9}$/;
                    // 这里定义的reg变量没有加引号，所以是一种特殊的数据类型，即正则，可以直接用test()方法
                    if (reg.test(value)) {
                        callback();
                    } else {
                        // 具体错误信息没有提示
                        return callback(new Error("请输入正确的手机号"));
                    }
                }
            };
            return {
                bookForm:{
                  address:'',
                  defaultType:1,//是否是默认
                  id:'',
                  deliveryName:'',
                  deliveryPhone:'',
                  shopName:'',
                  proCode:'',
                  proName:'',
                  cityCode:'',
                  cityName:'',
                  areaCode:'',
                  areaName:'',
                  villageName:'',
                  villageCode:'',
                },
                isAdd:true,
                bankFlag:1,
                bankName:'',
                bankCard:'',
                isRealname:false,//是否实名认证判断
                inputName:'',//姓名
                inputIdCard:'',//身份证号
                authenticationDialogVisible:false,//实名认证弹窗
                tableData:[],
                // uploadUrl:'http://192.168.1.120:18080/tms-cloud-miniprogram-service/oss/fs/upload/image',
                orderSuccessDialogVisible: false,
                addressDialogFormVisible: false,
                intelligentFillingDialogVisible: false,
                addressReceiveDialogFormVisible: false,
                importHistoricalConsigneeDialogVisible: false,//导入历史收货人
                importHistoricalConsigneeForm:{
                    cPhone:'',
                    code:'',
                },
                captcha: null,
                intervalId: null,
                intervalIdSendMobileCode: null,//短信验证码倒计时
                countdown : 0,//短信验证码倒计时.
                orderNo:'',
                intelligentFillingText:'',
                intelligentFillingType:'',//1:发货人2:收货人
                delivery_receive_addressBook: '',//1:发货人地址簿2:收货人地址簿
                addressBookRadio: '',
                addressBook: [],
                addressBookReceive:[],//历史收货人
                activeReceiveIndex:'',//选中历史收货人
                queryReceiveCondition:'',
                /*历史收货人*/
                receiveOptions: [],//收货人省市区
                cityOptions: [],
                areaOptions: [],
                siteOptions: [],
                /*历史收货人*/


                /*历史收货人修改*/
                receiveOptionsEdit: [],
                cityOptionsEdit: [],
                areaOptionsEdit: [],
                siteOptionsEdit: [],
                /*历史收货人修改*/

                /*下单发货人省市区数据*/
                deliveryOptions: [],
                deliveryCityOptions: [],
                deliveryAreaOptions: [],
                deliverySiteOptions: [],
                /*下单发货人省市区数据*/


                /*修改发货人省市区数据* */
                deliveryOptionsEdit: [],
                deliveryCityOptionsEdit: [],
                deliveryAreaOptionsEdit: [],
                deliverySiteOptionsEdit: [],
                /*修改发货人省市区数据*/
                fileImgList:[],

                imageUrl: '',
                optionsPackaging: [
                    {id: 1, name: '纸箱'},
                    {id: 2, name: '编织袋'},
                    {id: 3, name: '泡沫箱'},
                    {id: 4, name: '木箱'},
                    {id: 5, name: '塑料袋'},
                    {id: 6, name: '回单'},
                    {id: 7, name: '无'},
                    {id: 8, name: '纸'},
                    {id: 9, name: '袋'},
                    {id: 10, name: '桶'},
                    {id: 11, name: '木'},
                    {id: 12, name: '膜'},
                    {id: 13, name: '托盘'},
                ],
                form: {
                    address: '',//发货详细地址
                    areaCode: '',//发货区代码
                    areaName: '',//发货区名称
                    cityCode: '',//发货市代码
                    cityName: '',//发货市名称
                    deliveryName: '',//发货姓名
                    deliveryPhone: '',//发货电话
                    deliveryVillageName: '',//发货乡名称
                    goodName: '',//货物名称
                    goodsPic: '',//图片
                    goodsVolume: '',//货物体积（新增）
                    goodsWeight: '',//货物重量（新增）
                    id: '',
                    lat: '',//发货纬度
                    lon: '',//发货经度
                    packageId: 1,//包装id
                    paragraph: '',//代收货款
                    paymentType: '1',//运费付款方式：1提付 2现付
                    proCode: '',//发货省代码
                    proName: '',//发货省名称
                    receiveAddress: '',//收货详细地址
                    receiveAddrId: '',//收货人id（调整 userId 改为 receiveAddrId）
                    receiveAreaCode: '',//收货区代码
                    receiveAreaName: '',//收货区名称
                    receiveCityCode: '',//收货市代码
                    receiveCityName: '',//收货市名称
                    receiveLat: '',//收货纬度
                    receiveLon: '',//收货经度
                    receiveName: '',//收货姓名
                    receivePhone: '',//收货电话
                    receiveProCode: '',//收货省代码
                    receiveProName: '',//收货省名称
                    receiveShopName: '',//收货公司/店名
                    shopName: '',//发货公司/店名
                    unit: '',//货物件数
                    villageId: '',//(收货地址簿)街道id（新增）
                    villageName: '',//(收货地址簿)街道名称（新增）
                    villageType: '',//(收货地址簿) 街道类型|1：机构；2：目的地（新增）
                },
                rules: {
                    deliveryName: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        {min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur'}
                    ],
                    deliveryPhone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        {min: 11, max: 11, message: '输入11位手机号', trigger: 'blur'},
                        {validator: checkPhone, trigger: 'blur'}
                    ],
                    address: [
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                    ],
                    receiveName: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        {min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur'}
                    ],
                    receivePhone: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        {min: 11, max: 11, message: '输入11位手机号', trigger: 'blur'},
                        {validator: checkPhone, trigger: 'blur'}
                    ],
                    receiveAddress: [
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                    ],
                    goodName: [
                        {required: true, message: '请输入货物名称', trigger: 'blur'},
                        {min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur'}
                    ],
                    unit: [
                        {required: true, message: '请输入货物件数', trigger: 'blur'}
                    ],
                    goodsWeight: [
                        {required: true, message: '请输入货物重量', trigger: 'blur'}
                    ],
                    goodsVolume: [
                        {required: true, message: '请输入货物体积', trigger: 'blur'}
                    ],
                }
            };
        },
        watch:{
            form:{
                handler(val){
                    val.goodsWeight=decimalTow(val.goodsWeight,6);
                    val.goodsVolume=decimalTow(val.goodsVolume,6);
                    val.paragraph=decimalTow(val.paragraph);
                    val.unit=isNotzero(val.unit);
                },
                deep:true
            }
        },
        methods: {
          //发货人地址簿选中id
          handleDeliveryAddress(val){
            this.addressBookRadio = val;
          },
          changeThis() {
            this.$forceUpdate()
          },
          //新增地址簿
          newAdd() {
            this.bookForm = {
              address:'',
              defaultType:1,//是否是默认
              id:'',
              deliveryName:'',
              deliveryPhone:'',
              shopName:'',
              proCode:'',
              proName:'',
              cityCode:'',
              cityName:'',
              areaCode:'',
              areaName:'',
              villageName:'',
              villageCode:'',
            };
            this.isAdd = false;
            this.getDeliveryPro('', 'pro','edit');//修改发货人获取发货省市区
          },
          //新增地址簿返回
          goBack(){
            this.isAdd = true;
            this.getDeliveryAddress();
          },
          //新增地址簿保存
          saveAdd(){
            if(!this.bookForm.deliveryName){this.$message.error('请输入姓名！');return false;}
            if(!this.bookForm.deliveryPhone){this.$message.error('请输入电话！');return false;}
            if(!this.phoneReg.test(this.bookForm.deliveryPhone)){this.$message.error('请输入正确电话！');return false;}
            if(!this.bookForm.proName){this.$message.error('请选择省！');return false;}
            if(!this.bookForm.cityName){this.$message.error('请选择市！');return false;}
            if(!this.bookForm.areaName){this.$message.error('请选择区！');return false;}
            if(!this.bookForm.villageName){this.$message.error('请选街道/站点！');return false;}
            if(!this.bookForm.address){this.$message.error('请补充详细地址！');return false;}
            else{
              saveDeliveryAddress(this.bookForm).then(res=>{
                if(res.code === 200){
                  this.$message.success('保存成功！');
                  this.getDeliveryAddress();
                  this.isAdd = true;
                }
              })
            }
          },
          //查找绑定的银行卡
          getCustomerBankInfo() {
            findCustomerBankInfo().then((res) => {
              if (res.code === 200) {
                if(res.data.bankFlag ==2){//有
                  this.bankFlag = res.data.bankFlag;
                  this.bankName = res.data.bankName;
                  this.bankCard = res.data.bankCard;
                  var reg = /^(\d{4})\d+(\d{4})$/;
                  this.bankCard = this.bankCard.replace(reg,"$1 **** **** $2") ;
                  console.log(this.bankFlag,this.bankName,this.bankCard)
                }else{//没有
                  //this.$router.push({ path: 'changeInformation' })//跳转绑定银行卡
                }
              }
            })
          },
            toDetail(){
              // this.$router.push({ name: 'netOrderInfo' ,params: { data: this.tableData }})
              this.$router.push({ name: 'myOrderList' ,params: { data: this.tableData }})
            },
            sendDataGet(data){
                this.importHistoricalConsigneeForm.cPhone = data.phoneNumber;
                this.importHistoricalConsigneeForm.code = data.codeSMS;
            },
            receiveExportOn(){
                if(!this.importHistoricalConsigneeForm.cPhone){
                    this.$message({
                        type: 'error',
                        message: '请输入手机号',
                        offset: 80,
                    });
                    return false
                }
                if (!this.phoneReg.test(this.importHistoricalConsigneeForm.cPhone)) {
                    this.$message({
                        type: 'error',
                        message: '请输入正确手机号',
                        offset: 80,
                    });
                    return false
                }
                if (!this.importHistoricalConsigneeForm.code) {
                    this.$message({
                        type: 'error',
                        message: '请输入验证码',
                        offset: 80,
                    });
                    return false
                }
                let loginPhone = JSON.parse(localStorage.getItem('userObj'))?JSON.parse(localStorage.getItem('userObj')).loginPhone:'';
                if(loginPhone && loginPhone == this.importHistoricalConsigneeForm.cPhone){
                    this.$message({
                        type: 'error',
                        message: '手机号不能与当前登录手机号一致'
                    });
                    return false
                }
                receiveExport({cPhone:this.importHistoricalConsigneeForm.cPhone,code:this.importHistoricalConsigneeForm.code}).then((res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '导入成功',
                            offset: 80,
                        });
                        this.importHistoricalConsigneeDialogVisible = false;
                    }else {
                        this.$message({
                            type: 'error',
                            message: '导入失败',
                            offset: 80,
                        });
                    }
                })

            },
            importHistoricalConsigneeOn(){
                this.importHistoricalConsigneeForm = {
                        cPhone:'',
                        code:'',
                };
                this.importHistoricalConsigneeDialogVisible = true;
            },
            intelligentFillingOn(type){
                this.intelligentFillingType = type;
                this.intelligentFillingText = '';
                this.intelligentFillingDialogVisible = true
            },
            intelligentFillingCheckAddress(){
                if(!this.intelligentFillingText){this.$message.error('请输入地址进行识别!');return false}
                checkAddress({address:this.intelligentFillingText}).then((res) => {
                    if (res.code === 200) {
                        if(this.intelligentFillingType ===1){//发货人
                            let item = res.data;
                            this.form.deliveryName = item.person;
                            this.form.deliveryPhone = item.phonenum;
                            this.form.deliveryVillageName = item.town;
                            this.form.proCode = item.province_code;
                            this.form.proName = item.province;
                            this.getDeliveryPro(item.province_code,'city','order','');
                            this.form.cityName = item.city;
                            this.form.cityCode = item.city_code;
                            this.getDeliveryPro(item.city_code,'area','order','');
                            this.form.areaName = item.county;
                            this.form.areaCode = item.county_code;
                            this.getDeliveryPro(item.county_code,'site','order','');
                            this.form.address = item.address;
                            this.form.lat = item.lat;
                            this.form.lon = item.lon;
                            this.intelligentFillingDialogVisible = false;
                            // this.form.deliveryVillageName = item.villageName;
                        }
                        if(this.intelligentFillingType ===2){//收货人
                            let item = res.data;
                            this.form.receiveName = item.person;
                            this.form.receivePhone = item.phonenum;
                            this.form.villageName = item.town;
                            this.form.receiveProCode = item.province_code;
                            this.form.receiveProName = item.province;
                            this.getCity('order','','click');
                            this.form.receiveCityName = item.city;
                            this.form.receiveCityCode = item.city_code;
                            this.getArea('order','','click');
                            this.form.receiveAreaName = item.county;
                            this.form.receiveAreaCode = item.county_code;
                            this.getSite('order','','click');
                            this.form.receiveAddress = item.address;
                            this.form.receiveLat = item.lat;
                            this.form.receiveLon = item.lon;
                            this.intelligentFillingDialogVisible = false;
                        }
                    }
                })
            },
            convertBase64UrlToBlob(urlData) {
                const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
                // 处理异常,将ascii码小于0的转换为大于0
                const ab = new ArrayBuffer(bytes.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i)
                }
                return new Blob([ab], {type: 'image/png'})
            },
            compress(fileObj, callback) {//压缩图片
                let _this=this;
                try {
                    const image = new Image();
                    image.src = URL.createObjectURL(fileObj);
                    image.onload = function () {
                        const that = this;
                        // 默认按比例压缩
                        let w = that.width;
                        let h = that.height;
                        // 最大尺寸限制
                        let maxWidth = 1200, maxHeight = 1200;
                        // 目标尺寸
                        let targetWidth = w, targetHeight = h;
                        // 图片尺寸超过400x400的限制
                        if (w > maxWidth || h > maxHeight) {
                            if (w / h > maxWidth / maxHeight) {
                                // 更宽，按照宽度限定尺寸
                                targetWidth = maxWidth;
                                targetHeight = Math.round(maxWidth * (h / w));
                            } else {
                                targetHeight = maxHeight;
                                targetWidth = Math.round(maxHeight * (w / h));
                            }
                        }
                        let quality = 0.7; // 默认图片质量为0.7
                        // 生成canvas
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        // 创建属性节点
                        const anw = document.createAttribute('width');
                        anw.nodeValue = targetWidth;
                        const anh = document.createAttribute('height');
                        anh.nodeValue = targetHeight;
                        canvas.setAttributeNode(anw);
                        canvas.setAttributeNode(anh);
                        ctx.drawImage(that, 0, 0, targetWidth, targetHeight);
                        // 图像质量
                        if (fileObj.quality && fileObj.quality <= 1 && fileObj.quality > 0) {
                            quality = fileObj.quality
                        }
                        // quality值越小，所绘制出的图像越模糊
                        const data = canvas.toDataURL('image/jpeg', quality);
                        // 压缩完成执行回调
                        const newFile = new File([_this.convertBase64UrlToBlob(data)], fileObj.name);
                        callback(newFile)
                    }
                } catch (e) {
                    console.log('压缩失败!')
                }
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleExceed(){
                this.$message.error('最多上传三张!');
            },
            imgHttpRequest(options){
                const formData = new FormData();
                formData.append('pic', options.file);


                axios.post(this.uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    for(let len=this.fileImgList.length-1, i=len;i>=0;i--){
                        if(options.file.uid === this.fileImgList[i].uid){
                            this.fileImgList[i].imgId = response.data.data;
                            break;
                        }
                    }
                    // this.$message({
                    //     message: '上传成功',
                    //     type: 'success'
                    // });
                }).catch(error => {
                    console.log(error)
                        for(let i = this.fileImgList.length -1;i>=0;i--){
                            if(this.fileImgList[i].uid === (options.file.uid)){
                                this.fileImgList.splice(i,1);
                            }
                        }
                        this.$message({message: error.data.msg, type: 'error',offset: 80,});

                    this.$message.error('上传失败');
                    // 错误处理逻辑
                });










                // this.compress(options.file, val => {
                //     let formData = new FormData();
                    // formData.append('userId', userId);
                    // formData.append('type', 3);
                    // formData.append('pic', options.file);
                    // console.log(val);
                    // uploadImage(formData).then(res=>{
                    //     console.log(res)
                    // })
                    // pAxiosAll.post(this.urls.uploadImageSingleUrl,formData,'blob','multipart/form-data').then(res => {
                    //     if(res.data.code === 200){
                    //         for(let len=this.fileImgList.length-1, i=len;i>=0;i--){
                    //             if(options.file.uid === this.fileImgList[i].uid){
                    //                 this.fileImgList[i].imgId = res.data.data.id;
                    //                 break;
                    //             }
                    //         }
                    //     }
                    //     else {
                    //         for(let i = this.fileImgList.length -1;i>=0;i--){
                    //             if(this.fileImgList[i].uid === (options.file.uid)){
                    //                 this.fileImgList.splice(i,1);
                    //             }
                    //         }
                    //     }
                    // }).catch(err => {
                    //     console.log(err);
                    // });
                // });
            },
            createOrder(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let Pic = [];
                        this.fileImgList.forEach(list => {Pic.push(list.imgId.id);});
                        this.form.goodsPic= Pic.length?Pic.join(','):'';
                        if(!this.form.deliveryVillageName){this.$message.error('请选择完整的发货省市区');return false}
                        if(!this.form.villageId || !this.form.villageName || !this.form.villageType ){this.$message.error('请选择完整的收货省市区'); return false}
                        if(JSON.parse(localStorage.getItem("userObj")).type !=2 ){this.$message.error('请完成实名认证'); return false}
                        createOrder(this.form).then(res=>{
                            if(res.code === 200){
                                this.tableData = res.data.netOrder
                                console.log(this.tableData)
                                // this.$message.success('下单成功');
                                this.orderNo = res.data.netOrder.orderNumber;
                                this.orderSuccessDialogVisible = true;
                                this.resetForm();

                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(){
                this.form = {
                        address: '',//发货详细地址
                        areaCode: '',//发货区代码
                        areaName: '',//发货区名称
                        cityCode: '',//发货市代码
                        cityName: '',//发货市名称
                        deliveryName: '',//发货姓名
                        deliveryPhone: '',//发货电话
                        deliveryVillageName: '',//发货乡名称
                        goodName: '',//货物名称
                        goodsPic: '',//图片
                        goodsVolume: '',//货物体积（新增）
                        goodsWeight: '',//货物重量（新增）
                        id: '',
                        lat: '',//发货纬度
                        lon: '',//发货经度
                        packageId: 1,//包装id
                        paragraph: '',//代收货款
                        paymentType: '1',//运费付款方式：1提付 2现付
                        proCode: '',//发货省代码
                        proName: '',//发货省名称
                        receiveAddress: '',//收货详细地址
                        receiveAddrId: '',//收货人id（调整 userId 改为 receiveAddrId）
                        receiveAreaCode: '',//收货区代码
                        receiveAreaName: '',//收货区名称
                        receiveCityCode: '',//收货市代码
                        receiveCityName: '',//收货市名称
                        receiveLat: '',//收货纬度
                        receiveLon: '',//收货经度
                        receiveName: '',//收货姓名
                        receivePhone: '',//收货电话
                        receiveProCode: '',//收货省代码
                        receiveProName: '',//收货省名称
                        receiveShopName: '',//收货公司/店名
                        shopName: '',//发货公司/店名
                        unit: '',//货物件数
                        villageId: '',//(收货地址簿)街道id（新增）
                        villageName: '',//(收货地址簿)街道名称（新增）
                        villageType: '',//(收货地址簿) 街道类型|1：机构；2：目的地（新增）
                };
                this.fileImgList = [];
            },
            async getCurrentCity(type) {
                try {
                    const res = await axios.get('https://restapi.amap.com/v3/ip?key=448f5b1bef139c2880884d123c851238');
                    // const cityInfo = res.data.city;
                    this.getMatchRegion(res.data.city,type)
                    // this.city = cityInfo;
                } catch (error) {
                    this.$message.error('定位失败！请打开定位后刷新页面');
                    console.error('Error fetching city info:', error);
                }
            },
            getMatchRegion(cityName,type) {
                let params = {
                    cityName: cityName
                };
                matchRegion(params).then((res) => {
                    if (res.code === 200) {
                        this.receiveOptions = res.data;
                        this.receiveOptionsEdit = res.data;
                      if(type === 'order_edit'){
                        this.getCity('order', this.form, '');//修改收货人获取发货省市区
                        this.getArea('order', this.form, '');//修改收货人获取发货省市区
                        this.getSite('order', this.form, '');//修改收货人获取发货省市区
                        this.setSiteType('order', this.form, '');//修改收货人获取发货省市区
                      }
                    }
                })
            },
            formatLabel(node) {
                console.log(node)
                // 自定义显示格式，例如："Option 1.1 (code: guid-1-1)"
                return `${node.name} (code: ${node.code})`;
            },
            getCity(source,item,click) {
                if(source === 'order' ){
                    if(click == 'click') {
                        this.cityOptions = [];
                        this.form.receiveCityCode = '';
                        this.form.receiveCityName = '';
                        this.form.receiveAreaName = '';
                        this.form.receiveAreaCode = '';
                        this.areaOptions = [];
                        this.form.villageId = '';
                        this.form.villageName = '';
                        this.form.villageType = '';
                        this.siteOptions = [];
                    }
                    for (let i = 0; i < this.receiveOptions.length; i++) {
                        if (this.receiveOptions[i].code === this.form.receiveProCode) {
                            this.cityOptions = this.receiveOptions[i].children;
                            this.form.receiveProName = this.receiveOptions[i].name;
                            break
                        }
                    }
                }
                if(source === 'edit'){
                    if(click == 'click') {
                        item.cityOptionsEdit = [];
                        item.cityCode = '';
                        item.cityName = '';
                        item.areaName = '';
                        item.areaCode = '';
                        item.areaOptionsEdit = [];
                        item.villageId = '';
                        item.villageName = '';
                        item.villageType = '';
                        item.siteOptionsEdit = [];
                    }
                    for (let i = 0; i < item.receiveOptionsEdit.length; i++) {
                        if (item.receiveOptionsEdit[i].code == item.proCode) {
                            item.cityOptionsEdit = item.receiveOptionsEdit[i].children;
                            item.proName = item.receiveOptionsEdit[i].name;
                            break
                        }
                    }
                }

            },
            getArea(source,item,click) {
                if(source === 'order' ) {
                    if(click == 'click') {
                        this.form.receiveAreaName = '';
                        this.form.receiveAreaCode = '';
                        this.areaOptions = [];
                        this.form.villageId = '';
                        this.form.villageName = '';
                        this.form.villageType = '';
                        this.siteOptions = [];
                    }
                    for (let i = 0; i < this.cityOptions.length; i++) {
                        if (this.cityOptions[i].code === this.form.receiveCityCode) {
                            this.areaOptions = this.cityOptions[i].children;
                            this.form.receiveCityName = this.cityOptions[i].name;
                            break
                        }
                    }
                }
                if(source === 'edit' ) {
                    if(click == 'click') {
                        item.areaName = '';
                        item.areaCode = '';
                        item.areaOptionsEdit = [];
                        item.villageId = '';
                        item.villageName = '';
                        item.villageType = '';
                        item.siteOptionsEdit = [];
                    }
                    for (let i = 0; i < item.cityOptionsEdit.length; i++) {
                        if (item.cityOptionsEdit[i].code === item.cityCode) {
                            item.areaOptionsEdit = item.cityOptionsEdit[i].children;
                            item.cityName = item.cityOptionsEdit[i].name;
                            break
                        }
                    }
                }
            },
            getSite(source,item,click) {
                if(source === 'order' ) {
                    if(click == 'click') {
                        this.form.villageId = '';
                        this.form.villageName = '';
                        this.form.villageType = '';
                        this.siteOptions = [];
                    }
                    for (let i = 0; i < this.areaOptions.length; i++) {
                        if (this.areaOptions[i].code === this.form.receiveAreaCode) {
                            this.siteOptions = this.areaOptions[i].children;
                            this.form.receiveAreaName = this.areaOptions[i].name;
                            break
                        }
                    }
                }
                if(source === 'edit'){
                    if(click == 'click') {
                        item.villageId = '';
                        item.villageName = '';
                        item.villageType = '';
                        item.siteOptionsEdit = [];
                    }
                    for (let i = 0; i < item.areaOptionsEdit.length; i++) {
                        if (item.areaOptionsEdit[i].code === item.areaCode) {
                            item.siteOptionsEdit = item.areaOptionsEdit[i].children;
                            item.areaName = item.areaOptionsEdit[i].name;
                            break
                        }
                    }
                }
            },
            setSiteType(source,item) {
                if(source === 'order'){
                    for (let i = 0; i < this.siteOptions.length; i++) {
                        if (this.siteOptions[i].id === this.form.villageId) {
                            this.form.villageName = this.siteOptions[i].name;
                            this.form.villageType = this.siteOptions[i].type;
                            break
                        }
                    }
                }
                if(source === 'edit'){
                    for (let i = 0; i < item.siteOptionsEdit.length; i++) {
                        if (item.siteOptionsEdit[i].id === item.villageId) {
                            item.villageName = item.siteOptionsEdit[i].name;
                            item.villageType = item.siteOptionsEdit[i].type;
                            break
                        }
                    }
                }

            },
            async getDeliveryPro(a, type,source,item,click) {
                try {
                    let params = {
                        keywords: a || "中国",
                        output: "JSON",
                        offset: 1e3,
                        page: 1,
                        key: '448f5b1bef139c2880884d123c851238',
                        subdistrict: 1
                    }
                    axios.get(
                        'https://restapi.amap.com/v3/config/district', {
                            params: params
                        }
                    ).then((res) => {
                        if(source == 'order'){
                            if (type == 'pro') {
                                this.deliveryOptions = res.data.districts[0].districts;

                            }
                            if (type == 'city') {
                                if(click == 'click') {
                                    this.deliveryCityOptions = [];
                                    this.form.cityName = '';
                                    this.form.cityCode = '';
                                    this.deliveryAreaOptions = [];
                                    this.form.areaCode = '';
                                    this.form.areaName = '';
                                    this.deliverySiteOptions = [];
                                    this.form.deliveryVillageName = '';
                                }
                                for (let i = 0; i < this.deliveryOptions.length; i++) {
                                    if (this.deliveryOptions[i].adcode === this.form.proCode) {
                                        this.form.proName = this.deliveryOptions[i].name;
                                        break
                                    }
                                }
                                this.deliveryCityOptions = res.data.districts[0].districts;


                            }
                            if (type == 'area') {
                                if(click == 'click') {
                                    this.deliveryAreaOptions = [];
                                    this.form.areaCode = '';
                                    this.form.areaName = '';
                                    this.deliverySiteOptions = [];
                                    this.form.deliveryVillageName = '';
                                }
                                for (let i = 0; i < this.deliveryCityOptions.length; i++) {
                                    if (this.deliveryCityOptions[i].adcode === this.form.cityCode) {
                                        this.form.cityName = this.deliveryCityOptions[i].name;
                                        break
                                    }
                                }
                                this.deliveryAreaOptions = res.data.districts[0].districts;


                            }
                            if (type == 'site') {
                                if(click == 'click') {
                                    this.deliverySiteOptions = [];
                                    this.form.deliveryVillageName = '';
                                }
                                for (let i = 0; i < this.deliveryAreaOptions.length; i++) {
                                    if (this.deliveryAreaOptions[i].adcode === this.form.areaCode) {
                                        this.form.areaName = this.deliveryAreaOptions[i].name;
                                        break
                                    }
                                }
                                this.deliverySiteOptions = res.data.districts[0].districts;

                            }
                        }
                        if(source == 'edit'){
                            if (type == 'pro') {
                                this.deliveryOptionsEdit = res.data.districts[0].districts;
                            }
                            if (type == 'city') {
                                if(click == 'click'){
                                    this.deliveryCityOptionsEdit = [];
                                    item.cityName = '';
                                    item.cityCode = '';
                                    this.deliveryAreaOptionsEdit = [];
                                    item.areaCode = '';
                                    item.areaName = '';
                                    this.deliverySiteOptionsEdit = [];
                                    item.villageName = '';
                                }
                                for (let i = 0; i < this.deliveryOptionsEdit.length; i++) {
                                    if (this.deliveryOptionsEdit[i].adcode === item.proCode) {
                                        item.proName = this.deliveryOptionsEdit[i].name;
                                        break
                                    }
                                }
                                this.deliveryCityOptionsEdit = res.data.districts[0].districts;
                            }
                            if (type == 'area') {
                                if(click == 'click') {
                                    this.deliveryAreaOptionsEdit = [];
                                    item.areaCode = '';
                                    item.areaName = '';
                                    this.deliverySiteOptionsEdit = [];
                                    item.villageName = '';
                                }
                                for (let i = 0; i < this.deliveryCityOptionsEdit.length; i++) {
                                    if (this.deliveryCityOptionsEdit[i].adcode === item.cityCode) {
                                        item.cityName = this.deliveryCityOptionsEdit[i].name;
                                        break
                                    }
                                }
                                this.deliveryAreaOptionsEdit = res.data.districts[0].districts;
                            }
                            if (type == 'site') {
                                if(click == 'click') {
                                    this.deliverySiteOptionsEdit = [];
                                    item.villageName = '';
                                }
                                for (let i = 0; i < this.deliveryAreaOptionsEdit.length; i++) {
                                    if (this.deliveryAreaOptionsEdit[i].adcode === item.areaCode) {
                                        item.areaName = this.deliveryAreaOptionsEdit[i].name;
                                        break
                                    }
                                }

                                this.deliverySiteOptionsEdit = res.data.districts[0].districts;
                            }
                        }


                    }).catch((err) => {
                        console.log(err);
                    })


                } catch (error) {
                    this.$message.error('请求省市区失败！');
                    console.error('Error fetching city info:', error);
                }
            },
            getDeliveryAddress() {
                this.isAdd = true;
                let params = {
                    pageNum: 1,
                    pageSize: 100
                };
                findDeliveryAddress(params).then((res) => {
                    if (res.code === 200) {
                        this.delivery_receive_addressBook = 1;//发货人地址簿
                        this.addressBook = res.data.list;
                        this.addressBook.forEach(val => {
                            this.$set(val, 'disabled', true);
                            // this.$set(val, 'villageName', deliver);
                            this.getDeliveryPro('', 'pro','edit');//修改发货人获取发货省市区
                            this.getDeliveryPro(val.proCode, 'city','edit',val);//修改发货人获取发货省市区
                            this.getDeliveryPro(val.cityCode, 'area','edit',val);//修改发货人获取发货省市区
                            this.getDeliveryPro(val.areaCode, 'site','edit',val);//修改发货人获取发货省市区
                        });
                        this.addressDialogFormVisible = true;
                        this.addressBookRadio = '';
                    }
                })
            },
            editAddress(val, index) {
                this.addressBook[index].disabled = false;
            },
            confirmAddressBook(){
                if(!this.addressBookRadio){
                    this.$message.error('请选择地址簿');
                    return false
                }
                let item = {};
                item = this.addressBook.find(val=>val.id=== this.addressBookRadio);//选中地址簿信息
                if(this.delivery_receive_addressBook === 1){//发货人信息
                    this.form.deliveryName = item.deliveryName;
                    this.form.deliveryPhone = item.deliveryPhone;
                    this.addressDialogFormVisible = false;
                    // this.getDeliveryPro('','pro');
                    this.form.proCode = item.proCode;
                    this.form.proName = item.proName;
                    this.getDeliveryPro(item.proCode,'city','order','');
                    this.form.cityName = item.cityName;
                    this.form.cityCode = item.cityCode;
                    this.getDeliveryPro(item.cityCode,'area','order','');
                    this.form.areaName = item.areaName;
                    this.form.areaCode = item.areaCode;
                    this.getDeliveryPro(item.areaCode,'site','order','');
                    this.form.address = item.address;
                    this.form.shopName = item.shopName;
                    this.form.deliveryVillageName = item.villageName;
                }
            },
            editDeliveryAddress(params){//修改保存发货人地址
                if(!params.deliveryName){this.$message.error('请输入姓名！');return false;}
                if(!params.deliveryPhone){this.$message.error('请输入电话！');return false;}
                if(!this.phoneReg.test(params.deliveryPhone)){this.$message.error('请输入正确电话！');return false;}
                if(!params.proName){this.$message.error('请选择省！');return false;}
                if(!params.cityName){this.$message.error('请选择市！');return false;}
                if(!params.areaName){this.$message.error('请选择区！');return false;}
                if(!params.villageName){this.$message.error('请选街道/站点！');return false;}
                if(!params.address){this.$message.error('请补充详细地址！');return false;}
                saveDeliveryAddress(params).then(res=>{
                    if(res.code === 200){
                        this.$message.success('保存成功！');
                        this.getDeliveryAddress();
                    }
                })
            },
            cancelEdit(val, index) {

                if (this.delivery_receive_addressBook === 1) {
                    this.addressBook[index].disabled = true;
                    this.getDeliveryAddress();//刷新发货人地址簿
                }
                if (this.delivery_receive_addressBook === 2) {
                    this.addressBookReceive[index].disabled = true;
                    this.getReceiveAddress();//刷新历史收货人地址簿
                }

            },
            handleCloseDrawerDialogFormVisible() {
                this.addressDialogFormVisible = false;
            },
            handleCloseDrawerReceiveDialogFormVisible() {
                this.addressReceiveDialogFormVisible = false;
                this.queryReceiveCondition = '';
            },
            getReceiveAddress() {
                let params = {
                    pageNum: 1,
                    pageSize: 100,
                    condition: this.queryReceiveCondition,
                };
                findReceiveAddress(params).then((res) => {
                    if (res.code === 200) {
                        this.delivery_receive_addressBook = 2;//历史收货人
                        this.addressBookReceive = res.data;
                        this.addressBookReceive.forEach(val => {
                            this.$set(val, 'disabled', true);
                            this.$set(val, 'receiveOptionsEdit', this.receiveOptionsEdit);
                            this.$set(val, 'cityOptionsEdit', []);
                            this.$set(val, 'areaOptionsEdit', []);
                            this.$set(val, 'siteOptionsEdit', []);
                            // this.$set(val, 'villageName', deliver);
                            this.getCity('edit', val, '');//修改收货人获取发货省市区
                            this.getArea('edit', val, '');//修改收货人获取发货省市区
                            this.getSite('edit', val, '');//修改收货人获取发货省市区
                            this.setSiteType('edit', val, '');//修改收货人获取发货省市区
                        });
                        this.addressReceiveDialogFormVisible = true;
                        this.activeReceiveIndex = '';
                    }
                })
            },
            selectReceiveExtraction(index){
                this.activeReceiveIndex = index
            },
            editReceiveAddress(val, index) {
                this.addressBookReceive[index].disabled = false;
            },
            createReceiveAddress(params){//修改保存历史收货人
                if(!params.receiveName){this.$message.error('请输入姓名！');return false;}
                if(!params.receivePhone){this.$message.error('请输入电话！');return false;}
                if(!this.phoneReg.test(params.receivePhone)){this.$message.error('请输入正确电话！');return false;}
                if(!params.proName){this.$message.error('请选择省！');return false;}
                if(!params.cityName){this.$message.error('请选择市！');return false;}
                if(!params.areaName){this.$message.error('请选择区！');return false;}
                if(!params.villageName){this.$message.error('请选街道/站点！');return false;}
                this.$delete(params, 'receiveOptionsEdit');
                this.$delete(params, 'cityOptionsEdit');
                this.$delete(params, 'areaOptionsEdit');
                this.$delete(params, 'siteOptionsEdit');
                createReceiveAddress(params).then(res=>{
                    if(res.code === 200){
                        this.$message.success('保存成功！');
                        this.getReceiveAddress();
                    }else {
                        this.$message.error(res.msg);
                    }
                })
            },
            confirmAddressReceiveBook(){
                    if(this.activeReceiveIndex === ''){this.$message.error('请选择收货人信息！');return false;}
                    let val = this.addressBookReceive[this.activeReceiveIndex];
                    this.form.receiveAddress= val.address;//收货详细地址
                    this.form.receiveAddrId= val.id;//收货人id（调整 userId 改为 receiveAddrId）
                    this.form.receiveAreaCode= val.areaCode;//收货区代码
                    this.form.receiveAreaName= val.areaName;//收货区名称
                    this.form.receiveCityCode= val.cityCode;//收货市代码
                    this.form.receiveCityName= val.cityName;//收货市名称
                    this.form.receiveLat= val.lat;//收货纬度
                    this.form.receiveLon= val.lon;//收货经度
                    this.form.receiveName= val.receiveName;//收货姓名
                    this.form.receivePhone= val.receivePhone;//收货电话
                    this.form.receiveProCode= val.proCode;//收货省代码
                    this.form.receiveProName= val.proName;//收货省名称
                    this.form.receiveShopName= val.shopName;//收货公司/店名
                    this.form.villageId= val.villageId;//(收货地址簿)街道id（新增）
                    this.form.villageName= val.villageName;//(收货地址簿)街道名称（新增）
                    this.form.villageType= val.villageType;//(收货地址簿) 街道类型|1：机构；2：目的地（新增）
                    this.addressReceiveDialogFormVisible = false;
                    this.getCity('order', val, '');//修改收货人获取发货省市区
                    this.getArea('order', val, '');//修改收货人获取发货省市区
                    this.getSite('order', val, '');//修改收货人获取发货省市区
                    this.setSiteType('order', val, '');//修改收货人获取发货省市区
            },
          validateInputName(name){
            // 姓名正则表达式
            var reg = /^[\u4E00-\u9FA5]{2,25}$/;
            return reg.test(name);
          },
          validateInputIdCard(card){
            // 身份证正则表达式
            var reg = /^(\d{18}|\d{17}X|\d{17}x)$/;
            return reg.test(card);
          },
          //实名认证
          authentication(){
            if(!this.inputName){this.$message.error('请输入姓名!');return false;}
            if(!this.inputIdCard){this.$message.error('请输入身份证号!');return false;}
            if(!this.validateInputName(this.inputName)){this.$message.error('请输入正确姓名!');return false;}
            if(!this.validateInputIdCard(this.inputIdCard)){this.$message.error('请输入正确身份证号!');return false;}
            let params = {
                name:this.inputName,
                idcard:this.inputIdCard,
            }
            checkIdCard(params).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.authenticationDialogVisible = false;
                this.isRealname = false;
              }else{
                this.$message.error(res.msg);
                return false;
              }
            })
          }



        },
        mounted() {
          this.getCustomerBankInfo();
          if(JSON.parse(localStorage.getItem("userObj")).type != 2){
            this.isRealname = true
          }else{
            this.isRealname = false
          }
          this.getDeliveryPro('', 'pro','order');//发货省市区
          if(this.$route.params.data){
            this.form = this.$route.params.data;
            // this.form.villageId = this.$route.params.data.receiveId;
            this.$set(this.form,'villageId',this.$route.params.data.receiveId)
            this.getCurrentCity('order_edit');//获取当前城市  获取收货人省市区
            // this.form.proCode = item.proCode;
            // this.form.proName = item.proName;
            this.getDeliveryPro(this.form.proCode,'city','order','');
            // this.form.cityName = item.cityName;
            // this.form.cityCode = item.cityCode;
            this.getDeliveryPro(this.form.cityCode,'area','order','');
            // this.form.areaName = item.areaName;
            // this.form.areaCode = item.areaCode;
            this.getDeliveryPro(this.form.areaCode,'site','order','');
            // this.form.address = item.address;
            // this.form.shopName = item.shopName;
            // this.form.deliveryVillageName = this.form.villageName;
          }else {
            this.getCurrentCity('order_add');//获取当前城市  获取收货人省市区
          }
        },
    }
</script>

<style scoped>
    /deep/ .el-dialog{border-radius: 8px}
    .padding20{padding: 20px}
    .text-center{text-align: center}
    .text-left{text-align: left}
    /deep/.el-dialog__header {
        padding: 20px 20px 10px!important;
    }
    .pull-right{float: right}
    .text-right{text-align: right}
    .paddingRight{padding-right: 10px}
    .addressBook {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 150px);
        overflow-y: auto;
    }

    .addressBookContent {
        border-radius: 4px;
        width: 90%;
        margin: 10px auto;
        padding: 10px;
    }

    .select_addressBookContent {
        border: 1px solid #2f7bff;
        background-color: #EDEFF3;
    }

    .no_select_addressBookContent {
        border: 1px solid #e0e2e8;
    }

    .elm-select-r {
        width: 25%
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .background-color-blue {
        background-color: #2f7bff;
    }

    .background-color-red {
        background-color: red;
    }

    .orderText {
        color: white;
        border-radius: 50%;
        height: 33px;
        width: 33px;
        text-align: center;
        line-height: 33px;
    }

    .mainOrder {
        margin-top: 3%;
        display: flex;
        justify-content: space-around;
    }

    .box-card {
        width: 35%;
    }

    .goodsDetailText {
        font-weight: bold;
    }

    .box-card—bottom-main {
        display: flex;
        justify-content: center;
    }

    .box-card—bottom {
        width: 85%;
    }
    .el-form-item {
        margin-bottom: 10px!important;
    }


</style>
<style>
    .el-cascader {
        width: 100%;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
</style>
