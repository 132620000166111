<template>
    <div>
        <el-input v-model="form.phoneNumber" placeholder="请输入手机号" style="width: 100%;margin:0 auto"></el-input>
        <div style="display: flex;margin:10px 0;" >
            <el-input v-model="form.codeSMS" placeholder="请输入验证码" style="margin-right: 10px;"></el-input>
            <el-button type="primary" @click="open()" :disabled="countdown > 0">  {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }}</el-button>
        </div>
    </div>
</template>

<script>
    import {sendMobileCode} from "../api/homeApi";
    export default {
        name: "sendMobileCode",
        data() {
            return {
                form:{
                    phoneNumber:'',
                    codeSMS:'',
                },

                captcha: null,
                intervalId: null,
                intervalIdSendMobileCode: null,//短信验证码倒计时
                countdown : 0,//短信验证码倒计时.
            };
        },
        methods:{
            open() {
                if(!this.form.phoneNumber){
                    this.$message({
                        type: 'error',
                        message: '请输入手机号'
                    });
                    return false
                }
                if (!this.phoneReg.test(this.form.phoneNumber)) {
                    this.$message({
                        type: 'error',
                        message: '请输入正确手机号'
                    });
                    return false
                }
                if (!this.captcha) {
                    // 因为mounted的时候验证码的js还没加载，因此JumeiActionCaptcha实例必须在open的时候创建
                    this.captcha = new JumeiActionCaptcha(this.CAPTCHA_APP_ID, this.callback, {})
                }
                this.captcha.show()
            },
            callback(captchaRes) {
                // 属性如下：
                // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
                // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
                // bizState    Any       自定义透传参数。
                // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
                if (captchaRes.ret === 0) {
                    console.log('客户端验证成功')
                    // todo 此处需要调用接入者的服务端接口，完成验证，详见服务端接入说明
                    // ticket  randstr传给接口
                    let params = {
                        phoneNum:this.form.phoneNumber,
                        randstr:captchaRes.randstr,
                        ticket:captchaRes.ticket,
                    };
                    sendMobileCode(params).then((res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '发送成功'
                            });
                            this.$emit('sendData', this.form);
                            this.startCountdown(60); // 假设倒计时60秒
                            // this.captcha.hide();
                        }else {
                            this.$message({
                                type: 'error',
                                message: res.msg
                            });
                        }
                    })
                } else {
                    console.log('验证失败')
                }
            },
            startCountdown(seconds) {
                this.countdown = seconds;
                this.intervalIdSendMobileCode = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown -= 1;
                    } else {
                        clearInterval(this.intervalIdSendMobileCode);
                    }
                }, 1000);
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
