const jwt = require('jsonwebtoken');
const secret = 'your-secret-key'; // 应该是一个复杂的密钥

const intNotzero = /^[1-9]\d*$/;//非零的正整数
// 加密方法，传入payload和过期时间
function encode(payload, expiresIn) {
    return jwt.sign(payload, secret, { expiresIn });
}

// 解密方法，尝试解码token
function decode(token) {
    try {
        return jwt.verify(token, secret);
    } catch (error) {
        throw new Error('Invalid or expired token');
    }
}
//保留两位小数
function decimalTow(value) {
    //先把非数字的都替换掉，除了数字和.
    value = value.toString().replace(/[^\d.]/g, "");
    //保证只有出现一个.而没有多个.
    value = value.replace(/\.{2,}/g, ".");// eslint-disable-line
    //必须保证第一个为数字而不是.
    value = value.replace(/^\./g, "");// eslint-disable-line
    //保证.只出现一次，而不能出现两次以上
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");// eslint-disable-line

    //如果第一位是0，第二位不是点，就用数字把点替换掉
    let len1 = parseInt(value.substr(0, 1));
    let len2 = value.substr(1, 1);

    if (value.length > 1 && len1 === 0 && len2 !== '.') {
        value = value.substr(1, value.length - 1);
    }
    //只能输入两个小数
    return value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
}
//不为零的正整数
function isNotzero(value) {

    if (value === "" || value === 0 || value === '0') {
        return "";
    } else if (value.length > 1 && (value.charAt(0) === '0' || value.charAt(0) === 0)) {
        return value.substring(1, value.length);
    } else if (!intNotzero.test(value)) {
        // return value.substring(0,value.length-1);
        return value.replace(/\D/g, '');
    } else {
        return parseInt(value);
    }
}
/**
 * 保留任意位小数
 * @param {*} num 实际数字
 * @param {*} decimal 保留位数
 */
// function keepAnyDecimal(num, decimal = 2) {
//     const level = Math.pow(10, decimal)
//     var result = parseFloat(num)
//     if (isNaN(result)) {
//         return ''
//     }
//     result = Math.round(num * level) / level
//     return result
// }

module.exports = {
    encode,
    decode,
    decimalTow,
    isNotzero
};

