import request from "../utils/request";
//我的运单-发货
export function findMyWayBill(params) {
    return request({
        url: "myWayBill/findMyWayBill",
        method: "post",
        params: params,
    });
}
//我的运单-收货
export function findMyReceiveOrder(params) {
    return request({
        url: "myWayBill/findMyReceiveOrder",
        method: "post",
        params: params,
    });
}
//查询我的订单
export function findMyOrder(params) {
    return request({
        url: "myOrder/findMyOrder",
        method: "post",
        params: params,
    });
}