import request from "../utils/request";
export function matchRegion(params) {//查询收货人省市区
    return request({
        url: "home/matchRegion",
        method: "post",
        params: params,
    });
}
export function findDeliveryAddress(params) {//发货人地址簿
    return request({
        url: "deliveryAddress/findDeliveryAddress",
        method: "post",
        params: params,
    });
}
export function saveDeliveryAddress(params) {//保存发货人
    return request({
        url: "deliveryAddress/saveDeliveryAddress",
        method: "post",
        params: params,
    });
}

export function findReceiveAddress(params) {//历史收货人
    return request({
        url: "receive/list",
        method: "post",
        params: params,
    });
}
export function createReceiveAddress(params) {//历史收货人
    return request({
        url: "receive/saveOrupdate",
        method: "post",
        params: params,
    });
}
export function createOrder(params) {//保存订单
    return request({
        url: "myOrder/saveMyOrder",
        method: "post",
        params: params,
    });
}
export function uploadImage(params) {//上传图片
    return request({
        url: "oss/fs/upload/image",
        method: "post",
        params: params,
        contentType:'multipart/form-data'
    });
}
export function checkAddress(params) {//地址识别
    return request({
        url: "receive/checkAddress",
        method: "post",
        params: params,
    });
}
export function receiveExport(params) {//导入历史收货人
    return request({
        url: "receive/export",
        method: "post",
        params: params,
    });
}
//身份认证
export function checkIdCard(params) {
    return request({
        url: "receive/checkIdCard",
        method: "post",
        params: params,
    });
}
